import { DataAccessService } from './../data-access.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectComponent } from 'ng-select';

@Component({
  selector: 'app-wsalesreturn',
  templateUrl: './wsalesreturn.component.html',
  styleUrls: ['./wsalesreturn.component.css']
})
export class WsalesreturnComponent implements OnInit {
  @ViewChild('cust') selectComponent: SelectComponent;
   debitnotes;
  return_to = 'Debit Note';
  isFirst = true;
  products;
  batches;
  distributors;
  batch_nos = [];
  distributors1 = [];
  invoice_date;
  invoice_no;
  exp_date = '2050-09';
  t_qty = 0;

  returnProducts = [];
  index = 0;

  tempList = {};
  p_name;
  batch_no;
  distributor_name;
  return_qty = 0;
  reason = 'Expiry';
  isSecond = false;
  debitNotes1 = [];
  prodName = '';
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getCustomerData();
    this.getReturnDiduction();
    this.getCreditNote();
     
  }

  isView = false;


  diductionList;

  getReturnDiduction() {
    this.service.get('master.php?type=getReturnDiduction')
    .subscribe(response => {
      this.diductionList = response;
    });
  }




  custData ;

  getCustomerData() {
    this.service.get('sales.php?type=getCustomerData').subscribe(response => {
      this.custData = response;
     });
  }

    igst = 0;
    sgst = 0;
    cgst = 0;
  CustsData = [];
 
  getProductDataByCust(event) {
     this.CustsData = event;
     this.service.get('return.php?type=getProductDataByCust&custName=' + event.value).subscribe(response => {
      this.products = response;
    });
  }

  selectedCustomer =[];


  getBatches(event) {
    this.service.get('return.php?type=getBatchByCustomer&custName=' + event.patient_name + '&p_name=' + event.value).subscribe(response => {
      this.batches = response;
    });
  }

  creditNoteData;
  getCreditNote() {
    this.service.get('return.php?type=getCreditNote').subscribe(response => {
      this.creditNoteData = response;
    });
  }




  selectedResult=[];

  view(i){

    this.selectedResult = this.creditNoteData[i];
    this.isView = true;

  }














  sale_rate = 0 ;
  order_no = '';
  pack_size = '';
  id = '';
  hsn = '';
  selectedSlot;
  getDistributors(index) {
    index = index - 1;
    this.t_qty = this.batches[index].SQty;
    this.exp_date = this.batches[index].exp_date;
    this.order_no = this.batches[index].order_no;
    this.pack_size = this.batches[index].pack_size;
    this.id = this.batches[index].id;
    this.hsn = this.batches[index].hsn;
    this.sale_rate = this.batches[index].sale_rate || 0;
    this.gst = this.batches[index].gst || 0;
    this.diduction = this.checkSlot(this.exp_date);
    console.log( this.diduction);

  }

  getInvoiceDetails(index) {
    index = index - 1;
    this.invoice_date = this.distributors[index].invoice_date;
    this.invoice_no = this.distributors[index].invoice_no;
  }

  taxable = 0;
  tax = 0;
  discAmt = 0;
  gst = 0 ;
  netTotal = 0 ;
  diduction = 0;


  checkQty(value){
    if(value > this.t_qty ){
      alert('Return Qty. Cant  Be Greater Than Sale Qty. !!!!!!!!!');
      this.return_qty = 0;
      return;
    }else{
      this.calculate();
    }

  }


  checkExpiry() {
    const currentDate = new Date(); // Get the current date
    const [year, month] = this.exp_date.split('-').map(Number); // Split and convert to numbers

    // Create a new date for the first day of the expiry month
    const expiryDate = new Date(year, month - 1, 1); // Month is 0-indexed in JS

    // Check if the current date is past the expiry date
    if (currentDate >= expiryDate) {
        return 'red'; // Return 'red' if the current date is equal to or past the expiry date
    }
  }

 
   
  
  checkSlot(expiry: string): any {
    const expiryDate = new Date(expiry); // Parse the expiry date
    const currentDate = new Date(); // Get the current date
  
    // Calculate year and month differences
    const yearDifference = expiryDate.getFullYear() - currentDate.getFullYear();
    const monthDifference = expiryDate.getMonth() - currentDate.getMonth();
  
    // Total months difference
    const totalMonthDifference = (yearDifference * 12) + monthDifference;
  
    console.log("Months difference:", totalMonthDifference);
  
    for (const slot of this.diductionList) {
      // Check if the slot's return month matches the month difference
      if (totalMonthDifference <= slot.returnMonth) {
        return slot.didcuction;  // Return the matching slot if within the return month range
      }
    }
  
    return 0; // Return 0 if no slots match
  }



  calculate(){
    //this.taxable = parseFloat(((this.sale_rate  * this.return_qty) ) .toFixed(2));
    this.taxable = parseFloat(((this.sale_rate * this.return_qty) * ((100 - this.diduction || 0) / 100)).toFixed(2));
    this.tax = parseFloat((this.taxable  * (this.gst / 100)).toFixed(2));
    if(this.CustsData['state'] == localStorage.getItem('state')){
      this.igst =  0;
      this.sgst = this.tax / 2;
      this.cgst = this.tax / 2;
    }else{
      this.igst =  this.tax;
      this.sgst = 0;
      this.cgst = 0;
    }
    this.netTotal = parseFloat((this.taxable  + this.tax).toFixed(2));
  }

  net_total = 0;
  tax_total = 0;
  taxable_total = 0;

  custName = '';
  addProduct() {
    this.tempList['custName'] = this.custName;
    this.tempList['p_name'] = this.p_name;
    this.tempList['batch_no'] = this.batch_no;
    this.tempList['sale_rate'] = this.sale_rate;
     this.tempList['saleQty'] = this.t_qty;
    this.tempList['exp_date'] = this.exp_date;
    this.tempList['diduction'] = this.diduction;
    this.tempList['taxable'] = this.taxable;
    this.tempList['gst'] = this.gst;
    this.tempList['tax'] = this.tax;
    this.tempList['igst'] = this.igst;
    this.tempList['cgst'] = this.cgst;
    this.tempList['sgst'] = this.sgst;
    this.tempList['netTotal'] = this.netTotal;
     this.tempList['return_qty'] = this.return_qty;
    this.tempList['reason'] = this.reason;
    this.tempList['order_no'] = this.order_no;
    this.tempList['pack_size'] = this.pack_size;
    this.tempList['id'] = this.id;
    this.tempList['hsn'] = this.hsn;
    this.returnProducts[this.index] = this.tempList;
    this.index++;

    this.taxable_total = parseFloat((this.taxable_total + +this.taxable).toFixed(2));
    this.tax_total = parseFloat((this.tax_total + this.tax).toFixed(2));
    this.net_total = parseFloat((+this.net_total + +this.netTotal).toFixed(2));

    this.tempList = {};
     this.p_name = '';
    this.batch_no = '';
    this.sale_rate = 0;
    this.t_qty =  0;
    this.exp_date = '';
    this.diduction =  0;
    this.taxable =  0;
    this.gst = 0;
    this.tax = 0;
    this.igst = 0;
    this.cgst = 0;
    this.sgst = 0;
    this.netTotal = 0;
    this.return_qty = 0;
    this.reason = '';
    this.order_no = '';
    this.pack_size = '';
    this.id = '';
    this.hsn = '';
    this.selectComponent.open();
  }

  saveReturnForm() {
    let temp ={};
    temp['products'] = this.returnProducts;
    temp['c_mail'] = this.CustsData['c_mail'];
    temp['c_cont'] = this.CustsData['c_cont'];
    temp['state'] = this.CustsData['state'];
    temp['gst_no'] = this.CustsData['gst_no'];
    temp['custName'] = this.CustsData['patient_name'];
    temp['patient_no'] = this.CustsData['patient_no'];
    temp['c_add'] = this.CustsData['c_add'];
    temp['net_total'] = this.net_total;
    temp['tax_total'] = this.tax_total;
    temp['taxable_total'] = this.taxable_total;

    this.service.post('return.php?type=saveCreditNote&entry_by='+localStorage.getItem('cl_saler_name'), JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.returnProducts = [];
        this.index = 0;
        this.tempList = {};
        this.p_name = '';
        this.batch_no = '';
        this.distributor_name = '';
        this.t_qty = 0;
        this.exp_date = '';
        this.invoice_date = '';
        this.invoice_no = '';
         this.isSecond = false;
        this.isFirst = true;
        this.CustsData = [];
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkReturn(value) {
    if (value === 'Debit Note') {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
  }

  filterTable(value) {
    this.debitNotes1 = [];
    let index = 0;
    const length = Object.keys(this.debitnotes).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.debitnotes[i].p_name + ' ' + this.debitnotes[i].batch_no + ' ' + this.debitnotes[i].distributor_name + ' ' + this.debitnotes[i].t_qty + ' ' + this.debitnotes[i].exp_date + ' ' + this.debitnotes[i].invoice_date + ' ' + this.debitnotes[i].invoice_no + ' ' + this.debitnotes[i].return_qty + ' ' + this.debitnotes[i].reason).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.debitNotes1[index] = this.debitnotes[i];
        index++;
      }
    }
  }

  showForm() {
    this.isFirst = false;
    this.isSecond = true;
  }

  closeForm() {
    this.isSecond = false;
    this.isFirst = true;
  }

  deletProd(index) {
 
    this.taxable_total = parseFloat((this.taxable_total - +this.returnProducts[index].taxable).toFixed(2));
    this.tax_total = parseFloat((this.tax_total - this.returnProducts[index].tax).toFixed(2));
    this.net_total = parseFloat((+this.net_total - +this.returnProducts[index].netTotal).toFixed(2));
 
    this.returnProducts.splice(index, 1);
    this.index--;
  }







  viewOrder() {
    
      window.open(this.service.url1 + 'creditNote.php?order_no=' + this.selectedResult['order_no'] + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
    
  }

















}




























